var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('div',{staticClass:"tab d-flex justify-space-between mt-10"},_vm._l((_vm.columns),function(ref){
var fields = ref.fields;
var key = ref.key;
return _c('div',{key:key,staticClass:"tab__column mr-2"},_vm._l((fields),function(ref){
var label = ref.label;
var value = ref.value;
var isNone = ref.isNone;
return _c('div',{key:label,staticClass:"tab-cell mb-5"},[_c('div',{staticClass:"tab-cell__label mb-2"},[_vm._v(_vm._s(label))]),(!isNone)?_c('div',{staticClass:"tab-cell__value"},[_vm._v(_vm._s(value))]):_c('div',{staticClass:"grey--text text--lighten-1 font-italic"},[_vm._v("none")])])}),0)}),0),_c('update-consultation-fee-modal',{attrs:{"country-id":_vm.countryId,"consultation-fee-settings":_vm.consultationFeeSettings,"edit-mode":""},on:{"update-fee-settings":_vm.updateConsultationFeeInfo},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"edit-button white--text",attrs:{"tile":"","large":"","outlined":"","color":"light-blue darken-4"}},on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-pencil")]),_vm._v(" Edit ")],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }