<template>
  <section>
    <div class="tab d-flex justify-space-between mt-10">
      <div v-for="{ fields, key } in columns" :key="key" class="tab__column mr-2">
        <div v-for="{ label, value, isNone } in fields" :key="label" class="tab-cell mb-5">
          <div class="tab-cell__label mb-2">{{ label }}</div>
          <div v-if="!isNone" class="tab-cell__value">
            {{ value }}
          </div>
          <div v-else class="grey--text text--lighten-1 font-italic">none</div>
        </div>
      </div>
    </div>

    <update-reward-modal
      :country-id="countryId"
      :loyalty-settings="loyaltyRewardsSettings"
      edit-mode
      @update-loyalty-rewards-settings="loadRewardsSettings"
    >
      <template v-slot:activator="{ on }">
        <v-btn
          tile
          large
          outlined
          color="light-blue darken-4"
          class="edit-button white--text"
          v-on="on"
        >
          <v-icon left>mdi-pencil</v-icon> Edit
        </v-btn>
      </template>
    </update-reward-modal>

    <section class="flex-grow-1 products-container">
      <data-table
        :headers="$options.productsHeadersConfig"
        :items="products"
        :options.sync="options"
        :loading="isLoading"
      >
        <template v-slot:item.reference="{ item }">
          <span class="cursor-pointer" @click="toggleProductLoyaltyPointsModal(true, item)">
            <img class="mr-2" :src="getProductIcon(item)" width="16" height="16" alt="Icon" />
            <span class="light-blue--text text--darken-4 font-weight-medium text-decoration-none">
              {{ $options.productTitles[item.reference] }}
            </span>
          </span>
        </template>
      </data-table>

      <update-product-loyalty-points-modal
        v-if="isProductLoyaltyPointsModalOpen"
        :is-open="isProductLoyaltyPointsModalOpen"
        :country-id="countryId"
        :product-loyalty-settings="selectedProduct"
        :modal-title="updateProductLoyaltyPointsModalTitle"
        @cancel="toggleProductLoyaltyPointsModal(false)"
        @success="onProductLoyaltyPointsUpdateSuccess"
      />
    </section>
  </section>
</template>

<script>
import DetailsField from '@/models/DetailsField';
import UpdateRewardModal from '@/components/countries/UpdateRewardModal.vue';
import UpdateProductLoyaltyPointsModal from '@/components/countries/UpdateProductLoyaltyPointsModal.vue';
import DataTable from '@/components/common/DataTable.vue';

import { getProductIcon } from '@/utils/products';
import { formatNumberWithSpaces } from '@/utils';
import { getLoyaltyRewardsSettings, getProductsLoyaltyCountrySettings } from '@/api/countries.api';

import { PRODUCT_TITLE, PRODUCT_REFERENCES } from '@/constants/treatment';

const PRODUCT_TABLE_HEADER_CONFIG = [
  {
    text: 'Product Name',
    value: 'reference',
    width: '280px'
  },
  {
    text: 'Loyalty program points',
    value: 'points'
  }
];

const AMAZON_GIFTCARD = {
  AMAZON_GIFTCARD: 'Amazon giftcard redeem threshold',
  AMAZON_GIFTCARD_TITLE: 'Amazon giftcard title'
};

const FREE_PRODUCTS = {
  FREE_PRODUCTS: 'Free products redeem threshold'
};

export default {
  name: 'LoyaltyProgramTab',
  components: { UpdateProductLoyaltyPointsModal, UpdateRewardModal, DataTable },
  productsHeadersConfig: PRODUCT_TABLE_HEADER_CONFIG,
  productTitles: PRODUCT_TITLE,
  props: {
    countryId: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      options: {},
      isLoading: false,
      products: [],
      loyaltyRewardsSettings: {},
      selectedProduct: null,
      productsForDisplay: Object.values(PRODUCT_REFERENCES)
    };
  },
  computed: {
    amazonField() {
      const { giftCardTitle, giftCardPoints } = this.loyaltyRewardsSettings;

      return [
        new DetailsField({
          label: AMAZON_GIFTCARD.AMAZON_GIFTCARD_TITLE,
          value: giftCardTitle,
          isNone: !giftCardTitle
        }),
        new DetailsField({
          label: AMAZON_GIFTCARD.AMAZON_GIFTCARD,
          value: formatNumberWithSpaces(giftCardPoints),
          isNone: !giftCardPoints
        })
      ];
    },
    freeProductsField() {
      const { freeProductsPoints } = this.loyaltyRewardsSettings;

      return [
        new DetailsField({
          label: FREE_PRODUCTS.FREE_PRODUCTS,
          value: formatNumberWithSpaces(freeProductsPoints),
          isNone: !freeProductsPoints
        })
      ];
    },
    columns() {
      return [
        {
          fields: this.amazonField,
          key: 'amazon-field'
        },
        {
          fields: this.freeProductsField,
          key: 'free-products-field'
        }
      ];
    },
    isProductLoyaltyPointsModalOpen() {
      return !!this.selectedProduct;
    },
    updateProductLoyaltyPointsModalTitle() {
      return this.isProductLoyaltyPointsModalOpen
        ? `Edit ${PRODUCT_TITLE[this.selectedProduct.reference]}`
        : 'Edit';
    }
  },
  created() {
    this.loadRewardsSettings();
    this.loadProductsLoyaltyConfigList();
  },
  methods: {
    async loadRewardsSettings() {
      const { data } = await getLoyaltyRewardsSettings(this.countryId);

      this.loyaltyRewardsSettings = data;
    },
    async loadProductsLoyaltyConfigList() {
      const { data: products } = await getProductsLoyaltyCountrySettings(this.countryId);

      this.products = products.filter(({ reference }) =>
        this.productsForDisplay.includes(reference)
      );
    },
    toggleProductLoyaltyPointsModal(isOpen, product) {
      this.selectedProduct = isOpen ? product : null;
    },
    getProductIcon(product) {
      return getProductIcon(product);
    },
    onProductLoyaltyPointsUpdateSuccess() {
      this.toggleProductLoyaltyPointsModal(false);
      this.loadProductsLoyaltyConfigList();
    }
  }
};
</script>

<style lang="scss" scoped>
.products-container {
  border-top: 1px solid #c8d5dd;
}
.tab {
  max-width: 750px;

  &-cell {
    &__label {
      font-size: 14px;
      line-height: 16px;
      color: #83939c;
    }

    &__value {
      font-size: 16px;
      line-height: 19px;
      color: #324752;
    }
  }
}

.edit-button {
  margin: 40px 0 30px;
}
</style>
