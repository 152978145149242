var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('data-table',{staticClass:"mt-12",attrs:{"headers":_vm.$options.tableHeadersConfig,"items":_vm.products,"disable-pagination":""},scopedSlots:_vm._u([{key:"item.reference",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"cursor-pointer",on:{"click":function($event){return _vm.toggleProductPricesModal(true, item)}}},[(item.iconUrl)?_c('img',{staticClass:"mr-2",attrs:{"src":item.iconUrl,"width":"16","height":"16","alt":"Icon"}}):_vm._e(),_c('span',{staticClass:"light-blue--text text--darken-4 font-weight-medium text-decoration-none"},[_vm._v(" "+_vm._s(_vm.formatValue(_vm.$options.fieldNames.NAME, item))+" ")])])]}},{key:"item.available",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatValue(_vm.$options.fieldNames.IS_PRODUCT_AVAILABLE, item))+" ")]}},{key:"item.firstDoctorLevelMinPrice",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatValue(_vm.$options.fieldNames.MIN_PRICE_LEVEL_ONE, item))+" ")]}},{key:"item.firstDoctorLevelMaxPrice",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatValue(_vm.$options.fieldNames.MAX_PRICE_LEVEL_ONE, item))+" ")]}},{key:"item.secondDoctorLevelMinPrice",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatValue(_vm.$options.fieldNames.MIN_PRICE_LEVEL_TWO, item))+" ")]}},{key:"item.secondDoctorLevelMaxPrice",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatValue(_vm.$options.fieldNames.MAX_PRICE_LEVEL_TWO, item))+" ")]}}])}),(_vm.isProductPricesModalOpen)?_c('product-prices-modal',{attrs:{"is-open":_vm.isProductPricesModalOpen,"country-id":_vm.countryId,"product-details":_vm.currentlySelectedProduct},on:{"cancel":function($event){return _vm.toggleProductPricesModal(false)},"success":_vm.onProductUpdate}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }