import httpClient from '@/api/httpClient';

import { COUNTRY_ENDPOINT } from '@/constants/endpoints';

export const getCountriesList = requestParams =>
  httpClient.post(`${COUNTRY_ENDPOINT}/findPaged`, requestParams);

export const getProductsByCountryId = countryId =>
  httpClient.get(`${COUNTRY_ENDPOINT}/${countryId}/productsCountrySettings`);

export const getCountryDetails = countryId => httpClient.get(`${COUNTRY_ENDPOINT}/${countryId}`);

export const updateProductForCountry = async (countryId, productSettings) =>
  httpClient.post(
    `${COUNTRY_ENDPOINT}/${countryId}/productsCountrySettings/update`,
    productSettings
  );

export const getConsultationFeeSettings = countryId =>
  httpClient.get(`${COUNTRY_ENDPOINT}/${countryId}/consultationFeeCountrySettings`);

export const updateConsultationFeeSettings = ({ countryId, feeSettings }) =>
  httpClient.post(
    `${COUNTRY_ENDPOINT}/${countryId}/consultationFeeCountrySettings/update`,
    feeSettings
  );

export const getLoyaltyRewardsSettings = countryId =>
  httpClient.get(`${COUNTRY_ENDPOINT}/${countryId}/loyaltyRewardsCountrySettings`);

export const updateLoyaltyRewardsSettings = ({ countryId, loyaltyRewardsSettings }) =>
  httpClient.post(
    `${COUNTRY_ENDPOINT}/${countryId}/loyaltyRewardsCountrySettings/update`,
    loyaltyRewardsSettings
  );

export const getProductsLoyaltyCountrySettings = countryId =>
  httpClient.get(`${COUNTRY_ENDPOINT}/${countryId}/productsLoyaltyCountrySettings`);

export const updateProductsLoyaltyCountrySettings = ({
  countryId,
  productsLoyaltyCountrySettings
}) =>
  httpClient.post(
    `${COUNTRY_ENDPOINT}/${countryId}/productsLoyaltyCountrySettings/update`,
    productsLoyaltyCountrySettings
  );

export const uploadSaudiArabiaActiveCities = file => {
  const uploadUrl = '/api/adminPanel/almajdouieCities/upload';

  const formData = new FormData();
  const fileName = `activeCities`;

  formData.append('file', file, fileName);

  return httpClient.post(uploadUrl, formData, { 'Content-Type': 'multipart/form-data' });
};
