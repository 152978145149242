import { INGREDIENT_REFERENCES } from '@/constants/treatment';

const PRODUCT_ICON = {
  PRODUCT: '/icons/product-icon.svg',
  INGREDIENT: '/icons/ingredient-icon.svg'
};

export const getProductIcon = ({ reference }) => {
  const isIngredient = INGREDIENT_REFERENCES.includes(reference);

  return isIngredient ? PRODUCT_ICON.INGREDIENT : PRODUCT_ICON.PRODUCT;
};
