<template>
  <div class="detail-page-template white fill-height">
    <div class="detail-page-template__header detail-header d-flex align-center">
      <div class="detail-header__back-icon d-flex align-center" @click="returnBack">
        <v-icon size="20">mdi-arrow-left</v-icon>
      </div>
      <slot name="header"></slot>
    </div>
    <div class="detail-page-template__tabs">
      <slot name="content"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DetailPageTemplate',
  methods: {
    returnBack() {
      this.$router.go(-1);
    }
  }
};
</script>

<style lang="scss" scoped>
.detail-page-template {
  padding: 60px 160px 60px 40px;

  &__tabs {
    padding: 50px 0 0 120px;
  }
}

.detail-header {
  width: 100%;

  &__back-icon {
    width: 80px;
    height: 80px;

    cursor: pointer;

    margin-right: 40px;
  }
}
</style>
