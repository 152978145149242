<template>
  <base-modal
    :value="isOpen"
    max-width="420"
    modal-title="Edit product"
    has-close-button
    @cancel="onCancel"
  >
    <template v-slot:modal-body>
      <v-form ref="productDetailsForm" class="pt-1 flex-grow-1">
        <div class="product-form__name-field name-field">
          <div class="name-field__label">Product name</div>
          <div class="name-field__value">
            {{ $options.productTitle[productDetails[$options.fieldNames.NAME]] }}
          </div>
        </div>

        <base-select
          v-model="updateProductData[$options.fieldNames.IS_PRODUCT_AVAILABLE]"
          class="mt-8"
          :items="$options.productAvailabilityOptions"
          label="Availability"
        />

        <div class="d-flex mt-4">
          <base-money-field
            v-model.number="updateProductData[$options.fieldNames.MIN_PRICE_LEVEL_ONE]"
            :label="formatFieldLabel($options.fieldNames.MIN_PRICE_LEVEL_ONE)"
            :rules="[$options.validationRules.REQUIRED]"
          />
          <base-money-field
            v-model.number="updateProductData[$options.fieldNames.MAX_PRICE_LEVEL_ONE]"
            class="ml-5"
            :label="formatFieldLabel($options.fieldNames.MAX_PRICE_LEVEL_ONE)"
            :rules="[$options.validationRules.REQUIRED]"
          />
        </div>

        <div class="d-flex mt-4">
          <base-money-field
            v-model.number="updateProductData[$options.fieldNames.MIN_PRICE_LEVEL_TWO]"
            :label="formatFieldLabel($options.fieldNames.MIN_PRICE_LEVEL_TWO)"
            :rules="[$options.validationRules.REQUIRED]"
          />
          <base-money-field
            v-model.number="updateProductData[$options.fieldNames.MAX_PRICE_LEVEL_TWO]"
            class="ml-5"
            :label="formatFieldLabel($options.fieldNames.MAX_PRICE_LEVEL_TWO)"
            :rules="[$options.validationRules.REQUIRED]"
          />
        </div>
      </v-form>
    </template>
    <template v-slot:modal-actions>
      <div class="flex">
        <v-btn
          class="white--text text-capitalize font-weight-bold"
          color="light-blue darken-4"
          tile
          @click="updateProduct"
        >
          Save
        </v-btn>
        <v-btn
          class="ml-5 text-capitalize font-weight-bold"
          color="light-blue darken-4"
          text
          @click="onCancel"
        >
          Cancel
        </v-btn>
      </div>
    </template>
  </base-modal>
</template>

<script>
import { pick } from 'ramda';

import BaseModal from '@/components/common/BaseModal.vue';
import BaseMoneyField from '@/components/common/BaseMoneyField.vue';
import BaseSelect from '@/components/common/BaseSelect.vue';

import { updateProductForCountry } from '@/api/countries.api';
import { getCurrencySymbol } from '@/utils';

import { PRODUCT_TITLE } from '@/constants/treatment';
import { PRODUCT_DETAILS_FIELD_NAME } from '@/constants/product';
import { VALIDATION_RULES } from '@/constants/common';

const FIELD_LABEL_FORMATTER = {
  [PRODUCT_DETAILS_FIELD_NAME.MIN_PRICE_LEVEL_ONE]: currency =>
    `Min price (dr level 1), ${getCurrencySymbol(currency)}`,
  [PRODUCT_DETAILS_FIELD_NAME.MAX_PRICE_LEVEL_ONE]: currency =>
    `Max price (dr level 1), ${getCurrencySymbol(currency)}`,
  [PRODUCT_DETAILS_FIELD_NAME.MIN_PRICE_LEVEL_TWO]: currency =>
    `Min price (dr level 2), ${getCurrencySymbol(currency)}`,
  [PRODUCT_DETAILS_FIELD_NAME.MAX_PRICE_LEVEL_TWO]: currency =>
    `Max price (dr level 2), ${getCurrencySymbol(currency)}`
};

const PRODUCT_AVAILABILITY_OPTIONS = [
  {
    text: 'yes',
    value: true
  },
  {
    text: 'n/a',
    value: false
  }
];

export default {
  name: 'ProductPricesModal',
  components: { BaseModal, BaseMoneyField, BaseSelect },
  productTitle: PRODUCT_TITLE,
  productAvailabilityOptions: PRODUCT_AVAILABILITY_OPTIONS,
  fieldNames: PRODUCT_DETAILS_FIELD_NAME,
  validationRules: VALIDATION_RULES,
  props: {
    countryId: {
      type: [String, Number],
      required: true
    },
    productDetails: {
      type: Object,
      required: true
    },
    isOpen: {
      type: Boolean,
      required: true
    }
  },
  data() {
    const updateProductData = pick(
      [
        PRODUCT_DETAILS_FIELD_NAME.ID,
        PRODUCT_DETAILS_FIELD_NAME.CURRENCY,
        PRODUCT_DETAILS_FIELD_NAME.IS_PRODUCT_AVAILABLE,
        PRODUCT_DETAILS_FIELD_NAME.MAX_PRICE_LEVEL_ONE,
        PRODUCT_DETAILS_FIELD_NAME.MIN_PRICE_LEVEL_TWO,
        PRODUCT_DETAILS_FIELD_NAME.MIN_PRICE_LEVEL_ONE,
        PRODUCT_DETAILS_FIELD_NAME.MAX_PRICE_LEVEL_TWO
      ],
      this.productDetails
    );

    return {
      updateProductData
    };
  },
  methods: {
    onCancel() {
      this.$emit('cancel');
    },
    async updateProduct() {
      const isValid = this.$refs.productDetailsForm.validate();

      if (!isValid) {
        return;
      }

      await updateProductForCountry(this.countryId, this.updateProductData);

      this.$emit('success');
    },
    formatFieldLabel(fieldName) {
      return FIELD_LABEL_FORMATTER[fieldName](
        this.productDetails[PRODUCT_DETAILS_FIELD_NAME.CURRENCY]
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.name-field {
  &__label {
    font-size: 14px;
    color: #83939c;
  }

  &__value {
    font-size: 16px;
    color: #324752;
  }
}
</style>
