<template>
  <section>
    <div class="tab d-flex justify-space-between mt-10">
      <div v-for="{ fields, key } in columns" :key="key" class="tab__column mr-2">
        <div v-for="{ label, value, isNone } in fields" :key="label" class="tab-cell mb-5">
          <div class="tab-cell__label mb-2">{{ label }}</div>
          <div v-if="!isNone" class="tab-cell__value">{{ value }}</div>
          <div v-else class="grey--text text--lighten-1 font-italic">none</div>
        </div>
      </div>
    </div>

    <update-consultation-fee-modal
      :country-id="countryId"
      :consultation-fee-settings="consultationFeeSettings"
      edit-mode
      @update-fee-settings="updateConsultationFeeInfo"
    >
      <template v-slot:activator="{ on }">
        <v-btn
          tile
          large
          outlined
          color="light-blue darken-4"
          class="edit-button white--text"
          v-on="on"
        >
          <v-icon left>mdi-pencil</v-icon> Edit
        </v-btn>
      </template>
    </update-consultation-fee-modal>
  </section>
</template>

<script>
import DetailsField from '@/models/DetailsField';
import UpdateConsultationFeeModal from '@/components/countries/UpdateConsultationFeeModal.vue';

import { getConsultationFeeSettings } from '@/api/countries.api';

const BASIC_FEE_LABEL = {
  BASIC_FEE: 'Basic Fee Value'
};

export default {
  name: 'ConsultationFeeTab',
  components: { UpdateConsultationFeeModal },
  props: {
    countryId: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      consultationFeeSettings: {}
    };
  },
  computed: {
    basicFeeField() {
      const { basicFeeValue } = this.consultationFeeSettings;

      return [
        new DetailsField({
          label: BASIC_FEE_LABEL.BASIC_FEE,
          value: `${basicFeeValue}%`,
          isNone: !basicFeeValue
        })
      ];
    },
    columns() {
      return [
        {
          fields: this.basicFeeField,
          key: 'general-fields'
        }
      ];
    }
  },
  async created() {
    this.loadConsultationFeeSettings();
  },
  methods: {
    async loadConsultationFeeSettings() {
      const { data } = await getConsultationFeeSettings(this.countryId);

      this.consultationFeeSettings = { ...data };
    },
    updateConsultationFeeInfo() {
      this.loadConsultationFeeSettings();
    }
  }
};
</script>

<style lang="scss" scoped>
.tab {
  max-width: 750px;

  &-cell {
    &__label {
      font-size: 14px;
      line-height: 16px;
      color: #83939c;
    }

    &__value {
      font-size: 16px;
      line-height: 19px;
      color: #324752;
    }
  }
}

.edit-button {
  margin-top: 230px;
}
</style>
