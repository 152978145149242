<template>
  <detail-page-template>
    <template v-slot:header>
      <details-page-header :header-info="headerInfo">
        <template v-slot:actions>
          <dots-dropdown :items="[]" />
        </template>
      </details-page-header>
    </template>

    <template v-slot:content>
      <v-tabs color="blue-grey darken-3" height="30">
        <v-tab class="text-none">General</v-tab>
        <v-tab class="text-none">Products prices</v-tab>
        <v-tab class="text-none">Consultation Fees</v-tab>
        <v-tab class="text-none">Loyalty program</v-tab>

        <v-tab-item>
          general
        </v-tab-item>

        <v-tab-item>
          <product-prices-tab :country-id="id" />
        </v-tab-item>

        <v-tab-item>
          <consultation-fee-tab :country-id="country.id" />
        </v-tab-item>

        <v-tab-item>
          <loyalty-program-tab :country-id="country.id" />
        </v-tab-item>
      </v-tabs>
    </template>
  </detail-page-template>
</template>

<script>
import DetailPageTemplate from '@/components/tempates/DetailPageTemplate.vue';
import DetailsPageHeader from '@/components/common/DetailsPageHeader.vue';
import DotsDropdown from '@/components/users-page/DotsDropdown.vue';
import ProductPricesTab from '@/components/countries/ProductPricesTab.vue';
import LoyaltyProgramTab from './LoyaltyProgramTab.vue';

import { getCountryDetails } from '@/api/countries.api';
import ConsultationFeeTab from './ConsultationFeeTab.vue';

export default {
  name: 'CountryDetailsPage',
  components: {
    DetailPageTemplate,
    DetailsPageHeader,
    DotsDropdown,
    ProductPricesTab,
    ConsultationFeeTab,
    LoyaltyProgramTab
  },
  props: {
    id: {
      type: [String, Number],
      required: true
    }
  },
  data() {
    return {
      country: {}
    };
  },
  computed: {
    headerInfo() {
      const { name, photo, id } = this.country;

      return {
        name,
        photo,
        id
      };
    }
  },
  async mounted() {
    const { data } = await getCountryDetails(this.id);
    this.country = data;
  }
};
</script>
