<template>
  <update-info-modal
    v-model="isModalShown"
    width="420"
    scrollable
    :loading="isLoading"
    modal-title="Edit consultation fees"
    action-button-label="Save"
    @close-modal="onModalClose"
    @action-button-click="onActionButtonClick"
    @cancel="onModalClose"
  >
    <template v-slot:activator="{ on }">
      <slot name="activator" :on="on"></slot>
    </template>
    <template v-slot:modal-body>
      <v-form ref="editConsultationFeeForm" class="pt-1">
        <base-text-field
          v-model="countryFeeSettings.basicFeeValue"
          label="Basic Fee Value, %"
          class="fee-info__field"
          :rules="[$options.validationRules.REQUIRED, $options.consultationFeeFieldsError]"
        />
      </v-form>
    </template>
  </update-info-modal>
</template>

<script>
import BaseTextField from '@/components/common/BaseTextField.vue';
import UpdateInfoModal from '@/components/common/UpdateInfoModal.vue';

import { updateConsultationFeeSettings } from '@/api/countries.api';

import { VALIDATION_RULES, ERROR_BUTTON_COLOR } from '@/constants/common';

const consultationFeeFieldsError = value => {
  const hasInvalidValue = value < 0 || value > 100;
  return !hasInvalidValue || 'Provide a value from 0 to 100';
};

export default {
  name: 'UpdateConsultationFeeModal',
  components: { UpdateInfoModal, BaseTextField },
  validationRules: VALIDATION_RULES,
  consultationFeeFieldsError,
  props: {
    countryId: {
      type: Number,
      default: null
    },
    consultationFeeSettings: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      countryFeeSettings: {},
      isModalShown: false,
      isLoading: false
    };
  },
  watch: {
    consultationFeeSettings(value) {
      this.countryFeeSettings = { ...value };
    }
  },
  methods: {
    onModalClose() {
      const { editConsultationFeeForm } = this.$refs;

      editConsultationFeeForm.resetValidation();

      this.countryFeeSettings = { ...this.consultationFeeSettings };
      this.isModalShown = false;
    },
    async onActionButtonClick() {
      const { editConsultationFeeForm } = this.$refs;

      if (!editConsultationFeeForm.validate()) {
        return;
      }

      this.isLoading = true;

      try {
        await updateConsultationFeeSettings({
          countryId: this.countryId,
          feeSettings: this.countryFeeSettings
        });

        this.isModalShown = false;
        this.$emit('update-fee-settings');
      } catch (error) {
        this.actionButtonColor = ERROR_BUTTON_COLOR;
      } finally {
        this.isLoading = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.fee-info__field {
  font-size: 14px;
  line-height: 16px;
}
</style>
