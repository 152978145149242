<template>
  <v-menu left offset-y>
    <template v-slot:activator="{ on }">
      <v-btn class="grey lighten-4" color="light-blue darken-4" icon v-on="on">
        <v-icon>mdi-dots-horizontal</v-icon>
      </v-btn>
    </template>

    <v-list>
      <v-list-item v-for="item in items" :key="item.id" @click="selectItem(item)">
        <v-list-item-title class="light-blue--text text--darken-4">
          {{ item.title }}
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: 'DotsDropdown',
  props: {
    items: {
      type: Array,
      required: true
    }
  },
  methods: {
    selectItem(item) {
      this.$emit('select-item', item);
    }
  }
};
</script>
