<template>
  <base-modal
    :value="isOpen"
    :modal-title="modalTitle"
    max-width="420"
    has-close-button
    @cancel="onCancel"
  >
    <template v-slot:modal-body>
      <v-form ref="productLoyaltyPointsUpdateForm" class="pt-1 flex-grow-1">
        <base-text-field
          v-model="pointsData.points"
          label="Loyalty program points"
          class="loyalty-rewards-info__field mb-10"
          :rules="[$options.validationRules.REQUIRED]"
        />
      </v-form>
    </template>
    <template v-slot:modal-actions>
      <div class="flex">
        <v-btn
          class="white--text text-capitalize font-weight-bold"
          :color="saveButtonColor"
          tile
          @click="updateProductsLoyaltySettings"
        >
          Save
        </v-btn>
        <v-btn
          class="ml-5 text-capitalize font-weight-bold"
          color="light-blue darken-4"
          text
          @click="onCancel"
        >
          Cancel
        </v-btn>
      </div>
    </template>
  </base-modal>
</template>

<script>
import BaseModal from '@/components/common/BaseModal.vue';
import BaseTextField from '@/components/common/BaseTextField.vue';

import { updateProductsLoyaltyCountrySettings } from '@/api/countries.api';

import { ERROR_BUTTON_COLOR, VALIDATION_RULES } from '@/constants/common';

export default {
  name: 'UpdateProductLoyaltyPointsModal',
  components: { BaseTextField, BaseModal },
  validationRules: VALIDATION_RULES,
  props: {
    countryId: {
      type: [String, Number],
      required: true
    },
    productLoyaltySettings: {
      type: Object,
      required: true
    },
    isOpen: {
      type: Boolean,
      required: true
    },
    modalTitle: {
      type: String,
      default: 'Edit'
    }
  },
  data() {
    return {
      pointsData: {},
      saveButtonColor: 'light-blue darken-4'
    };
  },
  mounted() {
    this.pointsData = { ...this.productLoyaltySettings };
  },
  methods: {
    onCancel() {
      this.$emit('cancel');
    },
    async updateProductsLoyaltySettings() {
      const isValid = this.$refs.productLoyaltyPointsUpdateForm.validate();

      if (!isValid) {
        return;
      }

      try {
        await updateProductsLoyaltyCountrySettings({
          countryId: this.countryId,
          productsLoyaltyCountrySettings: this.pointsData
        });

        this.$emit('success');
      } catch (error) {
        this.saveButtonColor = ERROR_BUTTON_COLOR;
      } finally {
        this.isLoading = false;
      }
    }
  }
};
</script>
