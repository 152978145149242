<template>
  <div>
    <data-table
      class="mt-12"
      :headers="$options.tableHeadersConfig"
      :items="products"
      disable-pagination
    >
      <template v-slot:item.reference="{ item }">
        <span class="cursor-pointer" @click="toggleProductPricesModal(true, item)">
          <img
            v-if="item.iconUrl"
            class="mr-2"
            :src="item.iconUrl"
            width="16"
            height="16"
            alt="Icon"
          />
          <span class="light-blue--text text--darken-4 font-weight-medium text-decoration-none">
            {{ formatValue($options.fieldNames.NAME, item) }}
          </span>
        </span>
      </template>

      <template v-slot:item.available="{ item }">
        {{ formatValue($options.fieldNames.IS_PRODUCT_AVAILABLE, item) }}
      </template>

      <template v-slot:item.firstDoctorLevelMinPrice="{ item }">
        {{ formatValue($options.fieldNames.MIN_PRICE_LEVEL_ONE, item) }}
      </template>

      <template v-slot:item.firstDoctorLevelMaxPrice="{ item }">
        {{ formatValue($options.fieldNames.MAX_PRICE_LEVEL_ONE, item) }}
      </template>

      <template v-slot:item.secondDoctorLevelMinPrice="{ item }">
        {{ formatValue($options.fieldNames.MIN_PRICE_LEVEL_TWO, item) }}
      </template>

      <template v-slot:item.secondDoctorLevelMaxPrice="{ item }">
        {{ formatValue($options.fieldNames.MAX_PRICE_LEVEL_TWO, item) }}
      </template>
    </data-table>

    <product-prices-modal
      v-if="isProductPricesModalOpen"
      :is-open="isProductPricesModalOpen"
      :country-id="countryId"
      :product-details="currentlySelectedProduct"
      @cancel="toggleProductPricesModal(false)"
      @success="onProductUpdate"
    />
  </div>
</template>

<script>
import DataTable from '@/components/common/DataTable.vue';
import ProductPricesModal from '@/components/countries/ProductPricesModal.vue';

import { getProductsByCountryId } from '@/api/countries.api';
import { getProductIcon } from '@/utils/products';
import { formatPrice } from '@/utils';

import { PRODUCT_TITLE, PRODUCT_REFERENCES } from '@/constants/treatment';
import { PRODUCT_DETAILS_FIELD_NAME } from '@/constants/product';

const priceFormatter = (price, item) => {
  if (!price) {
    return '-';
  }

  const { [PRODUCT_DETAILS_FIELD_NAME.CURRENCY]: currency } = item;

  return formatPrice({ currency, price });
};

const VALUE_FORMATTER = {
  [PRODUCT_DETAILS_FIELD_NAME.NAME]: value => PRODUCT_TITLE[value],
  [PRODUCT_DETAILS_FIELD_NAME.MIN_PRICE_LEVEL_ONE]: priceFormatter,
  [PRODUCT_DETAILS_FIELD_NAME.MAX_PRICE_LEVEL_ONE]: priceFormatter,
  [PRODUCT_DETAILS_FIELD_NAME.MIN_PRICE_LEVEL_TWO]: priceFormatter,
  [PRODUCT_DETAILS_FIELD_NAME.MAX_PRICE_LEVEL_TWO]: priceFormatter,
  [PRODUCT_DETAILS_FIELD_NAME.IS_PRODUCT_AVAILABLE]: value => (value ? 'yes' : 'n/a')
};

const COUNTRIES_TABLE_HEADER_CONFIG = [
  { text: 'Product name', value: PRODUCT_DETAILS_FIELD_NAME.NAME, sortable: true },
  {
    text: 'Availability',
    value: PRODUCT_DETAILS_FIELD_NAME.IS_PRODUCT_AVAILABLE,
    width: '200px',
    sortable: false
  },
  {
    text: 'Min price (dr level 1)',
    value: PRODUCT_DETAILS_FIELD_NAME.MIN_PRICE_LEVEL_ONE,
    width: '120px',
    sortable: false
  },
  {
    text: 'Max price (dr level 1)',
    value: PRODUCT_DETAILS_FIELD_NAME.MAX_PRICE_LEVEL_ONE,
    width: '120px',
    sortable: false
  },
  {
    text: 'Min price (dr level 2)',
    value: PRODUCT_DETAILS_FIELD_NAME.MIN_PRICE_LEVEL_TWO,
    width: '120px',
    sortable: false
  },
  {
    text: 'Max price (dr level 2)',
    value: PRODUCT_DETAILS_FIELD_NAME.MAX_PRICE_LEVEL_TWO,
    width: '120px',
    sortable: false
  }
];

export default {
  name: 'ProductPricesTab',
  components: { DataTable, ProductPricesModal },
  fieldNames: PRODUCT_DETAILS_FIELD_NAME,
  tableHeadersConfig: COUNTRIES_TABLE_HEADER_CONFIG,
  props: {
    countryId: {
      type: [String, Number],
      required: true
    }
  },
  data() {
    return {
      products: [],
      currentlySelectedProduct: null,
      productsForDisplay: Object.values(PRODUCT_REFERENCES)
    };
  },
  computed: {
    isProductPricesModalOpen() {
      return !!this.currentlySelectedProduct;
    }
  },
  async mounted() {
    await this.loadProducts();
  },
  methods: {
    async loadProducts() {
      const { data: products } = await getProductsByCountryId(this.countryId);

      this.products = products
        .filter(({ reference }) => this.productsForDisplay.includes(reference))
        .map(product => ({ ...product, iconUrl: getProductIcon(product) }));
    },
    formatValue(fieldName, item) {
      const fieldValue = item[fieldName];
      const formatter = VALUE_FORMATTER[fieldName] || (value => value);

      return formatter(fieldValue, item);
    },
    toggleProductPricesModal(isOpen, product) {
      this.currentlySelectedProduct = isOpen ? product : null;
    },
    async onProductUpdate() {
      await this.loadProducts();
      this.toggleProductPricesModal(false);
    }
  }
};
</script>
