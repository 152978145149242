export const TREATMENT_STATUSES = {
  TREATMENT_COMPLETED: 'Completed',
  ONGOING_TREATMENT: 'Ongoing',
  TREATMENT_STARTS_IN: 'New Treatment',
  INACTIVE: 'Inactive',
  NEW_QUESTIONNAIRE: 'New Questionnaire',
  OFFER_SENT: 'Offer sent',
  ORDER_UNDER_DELIVERY: 'Order under preparation',
  RESEND_OPERATION: 'Resend operation',
  ORDER_CANCELLED: 'Order cancelled',
  NEW_PATIENT: 'New Patient',
  NEW_PHOTO_ANALYSIS: 'New photo analysis'
};

export const TREATMENT_COMPOSITION_SECTION_NAMES = {
  TREAT_FACE: 'Universkin Serum P /Face',
  TREAT_EYES: 'Universkin Serum P /Eyes',
  CLEANSE: 'cleanse',
  MOISTURIZER: 'moisturizer',
  PROTECT: 'protect'
};

export const TREATMENT_PRODUCT_STATUS = {
  DOCTOR_RECOMMENDATION: 'DOCTOR_RECOMMENDATION',
  REMOVED_BY_PATIENT: 'REMOVED_BY_PATIENT',
  ADDED_BY_PATIENT: 'ADDED_BY_PATIENT',
  MODIFIED_BY_PATIENT: 'MODIFIED_BY_PATIENT'
};

export const SERUM_USAGE_KEYS = {
  MORNING_ONLY: 'morningOnly',
  MORNING_AND_EVENING: 'morningAndEvening',
  EVENING_ONLY: 'eveningOnly',
  ONE_MORNING_ONE_EVENING: 'oneMorningOneEvening'
};

export const SERUM_USAGE_ABBREVIATION = {
  [SERUM_USAGE_KEYS.MORNING_ONLY]: 'AM',
  [SERUM_USAGE_KEYS.MORNING_AND_EVENING]: 'AM/PM',
  [SERUM_USAGE_KEYS.EVENING_ONLY]: 'PM'
};

export const INGREDIENTS = {
  SERUM: 'serum',
  FERULIC: 'ferulic',
  DMAE: 'dmae',
  DPANTHENOL: 'dpanthenol',
  GLYCOLIC: 'glycolic',
  ARBUTIN: 'arbutin',
  KOJIC: 'kojic',
  SOD: 'sod',
  MADECA: 'madeca',
  AZELAIC: 'azelaic',
  RUTIN: 'rutin',
  SALICYLIC: 'salicylic',
  PHYTIC: 'phytic',
  ZINC: 'zinc',
  LACTO: 'lacto',
  NIACINAMIDE: 'niacinamide',
  ALOE: 'aloe',
  ISO: 'iso',
  RETINOL: 'retinol',
  ASCORBIC: 'ascorbic'
};

export const INGREDIENT_CONCENTRATIONS = {
  [INGREDIENTS.FERULIC]: 1.0,
  [INGREDIENTS.DMAE]: 2.5,
  [INGREDIENTS.DPANTHENOL]: 5.0,
  [INGREDIENTS.GLYCOLIC]: 7.0,
  [INGREDIENTS.ARBUTIN]: 3.0,
  [INGREDIENTS.KOJIC]: 2.0,
  [INGREDIENTS.SOD]: 2.0,
  [INGREDIENTS.MADECA]: 1.0,
  [INGREDIENTS.AZELAIC]: 5.4,
  [INGREDIENTS.RUTIN]: 3.2,
  [INGREDIENTS.SALICYLIC]: 2.0,
  [INGREDIENTS.PHYTIC]: 2.0,
  [INGREDIENTS.ZINC]: 2.5,
  [INGREDIENTS.LACTO]: 3.5,
  [INGREDIENTS.ASCORBIC]: 7.0,
  [INGREDIENTS.RETINOL]: 0.2,
  [INGREDIENTS.NIACINAMIDE]: 4.0,
  [INGREDIENTS.ALOE]: 5.4,
  [INGREDIENTS.ISO]: 2.0
};

export const PRODUCT_REFERENCES = {
  ACTIVE_UNIT: 'activeUnit',
  ACTIVE_UNIT_PROMO: 'activeUnit7plusOnline',
  SERUM_UNIT: 'serumUnit',
  SERUM_X2_UNIT: 'serum2Unit',
  NEXULTRA_O: 'nexUltraO',
  NEXULTRA_O1: 'nexUltraO1',
  NEXULTRA_O2: 'nexUltraO2',
  NEXULTRA_1: 'nexUltra1',
  NEXULTRA_2: 'nexUltra2',
  NEXULTRA_3: 'nexUltra3',
  NEXULTRA_B: 'nexUltraB',
  NEXULTRA_B1: 'nexUltraB1',
  NEXULTRA_B2: 'nexUltraB2',
  UNIVERSKIN_H: 'universkinH',
  UNIVERSKIN_M: 'universkinM',
  NEXULTRA_L: 'nexUltraL',
  NEXULTRA_L1: 'nexUltraL1',
  NEXULTRA_Z: 'nexUltraZ',
  NEXULTRA_UV: 'nexUltraUV',
  NEXULTRA_L_BRUSH: 'nexUltraLBrush',
  NEXULTRA_P_LIGHT: 'nexUltraPLight',
  NEXULTRA_P_RICH: 'nexUltraPRich',
  MICRONIDLING_FACE: 'microneedlingFace',
  MICRONIDLING_EYES: 'microneedlingEyes'
};

export const INGREDIENT_REFERENCES = [
  PRODUCT_REFERENCES.ACTIVE_UNIT,
  PRODUCT_REFERENCES.ACTIVE_UNIT_PROMO
];

export const PRODUCT_TITLE = {
  [PRODUCT_REFERENCES.ACTIVE_UNIT]: 'Active Ingredient',
  [PRODUCT_REFERENCES.ACTIVE_UNIT_PROMO]: 'Active Ingredient Promo',
  [PRODUCT_REFERENCES.SERUM_UNIT]: 'Universkin Serum P',
  [PRODUCT_REFERENCES.SERUM_X2_UNIT]: 'Universkin Serum P х2',
  [PRODUCT_REFERENCES.NEXULTRA_O1]: 'Nexultra O, 100ml',
  [PRODUCT_REFERENCES.NEXULTRA_O2]: 'Nexultra O, 200ml',
  [PRODUCT_REFERENCES.NEXULTRA_1]: 'Nexultra 1',
  [PRODUCT_REFERENCES.NEXULTRA_2]: 'Nexultra 2',
  [PRODUCT_REFERENCES.NEXULTRA_3]: 'Nexultra 3',
  [PRODUCT_REFERENCES.NEXULTRA_B1]: 'Nexultra B, 30ml',
  [PRODUCT_REFERENCES.NEXULTRA_B2]: 'Nexultra B, 100ml',
  [PRODUCT_REFERENCES.UNIVERSKIN_H]: 'Universkin H',
  [PRODUCT_REFERENCES.UNIVERSKIN_M]: 'Universkin M',
  [PRODUCT_REFERENCES.NEXULTRA_L1]: 'Nexultra L, 50ml',
  [PRODUCT_REFERENCES.NEXULTRA_Z]: 'nexUltraZ',
  [PRODUCT_REFERENCES.NEXULTRA_UV]: 'Nexultra UV',
  [PRODUCT_REFERENCES.NEXULTRA_L_BRUSH]: 'Nexultra L brush',
  [PRODUCT_REFERENCES.NEXULTRA_P_LIGHT]: 'Nexultra P light',
  [PRODUCT_REFERENCES.NEXULTRA_P_RICH]: 'Nexultra P rich',
  [PRODUCT_REFERENCES.MICRONIDLING_FACE]: 'S.tep face',
  [PRODUCT_REFERENCES.MICRONIDLING_EYES]: 'S.tep eyes'
};

export const CHANGABLE_SIZE_PRODUCTS = [
  PRODUCT_REFERENCES.NEXULTRA_B,
  PRODUCT_REFERENCES.NEXULTRA_L,
  PRODUCT_REFERENCES.NEXULTRA_O,
];

export const CHANGABLE_SIZE_PRODUCTS_BINDINGS = {
  [PRODUCT_REFERENCES.NEXULTRA_B]: {
    30: PRODUCT_REFERENCES.NEXULTRA_B1,
    100: PRODUCT_REFERENCES.NEXULTRA_B2
  },
  [PRODUCT_REFERENCES.NEXULTRA_O]: {
    100: PRODUCT_REFERENCES.NEXULTRA_O1,
    200: PRODUCT_REFERENCES.NEXULTRA_O2
  },
  [PRODUCT_REFERENCES.NEXULTRA_L]: {
    50: PRODUCT_REFERENCES.NEXULTRA_L1
  }
};
