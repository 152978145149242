var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('div',{staticClass:"tab d-flex justify-space-between mt-10"},_vm._l((_vm.columns),function(ref){
var fields = ref.fields;
var key = ref.key;
return _c('div',{key:key,staticClass:"tab__column mr-2"},_vm._l((fields),function(ref){
var label = ref.label;
var value = ref.value;
var isNone = ref.isNone;
return _c('div',{key:label,staticClass:"tab-cell mb-5"},[_c('div',{staticClass:"tab-cell__label mb-2"},[_vm._v(_vm._s(label))]),(!isNone)?_c('div',{staticClass:"tab-cell__value"},[_vm._v(" "+_vm._s(value)+" ")]):_c('div',{staticClass:"grey--text text--lighten-1 font-italic"},[_vm._v("none")])])}),0)}),0),_c('update-reward-modal',{attrs:{"country-id":_vm.countryId,"loyalty-settings":_vm.loyaltyRewardsSettings,"edit-mode":""},on:{"update-loyalty-rewards-settings":_vm.loadRewardsSettings},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"edit-button white--text",attrs:{"tile":"","large":"","outlined":"","color":"light-blue darken-4"}},on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-pencil")]),_vm._v(" Edit ")],1)]}}])}),_c('section',{staticClass:"flex-grow-1 products-container"},[_c('data-table',{attrs:{"headers":_vm.$options.productsHeadersConfig,"items":_vm.products,"options":_vm.options,"loading":_vm.isLoading},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.reference",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"cursor-pointer",on:{"click":function($event){return _vm.toggleProductLoyaltyPointsModal(true, item)}}},[_c('img',{staticClass:"mr-2",attrs:{"src":_vm.getProductIcon(item),"width":"16","height":"16","alt":"Icon"}}),_c('span',{staticClass:"light-blue--text text--darken-4 font-weight-medium text-decoration-none"},[_vm._v(" "+_vm._s(_vm.$options.productTitles[item.reference])+" ")])])]}}])}),(_vm.isProductLoyaltyPointsModalOpen)?_c('update-product-loyalty-points-modal',{attrs:{"is-open":_vm.isProductLoyaltyPointsModalOpen,"country-id":_vm.countryId,"product-loyalty-settings":_vm.selectedProduct,"modal-title":_vm.updateProductLoyaltyPointsModalTitle},on:{"cancel":function($event){return _vm.toggleProductLoyaltyPointsModal(false)},"success":_vm.onProductLoyaltyPointsUpdateSuccess}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }