export const PRODUCT_DETAILS_FIELD_NAME = {
  ID: 'id',
  NAME: 'reference',
  IS_PRODUCT_AVAILABLE: 'available',
  CURRENCY: 'currency',
  MIN_PRICE_LEVEL_ONE: 'firstDoctorLevelMinPrice',
  MAX_PRICE_LEVEL_ONE: 'firstDoctorLevelMaxPrice',
  MIN_PRICE_LEVEL_TWO: 'secondDoctorLevelMinPrice',
  MAX_PRICE_LEVEL_TWO: 'secondDoctorLevelMaxPrice'
};
