<template>
  <update-info-modal
    v-model="isModalShown"
    width="420"
    scrollable
    :loading="isLoading"
    modal-title="Edit"
    action-button-label="Save"
    :action-button-color="actionButtonColor"
    @close-modal="onModalClose"
    @action-button-click="onActionButtonClick"
    @cancel="onModalClose"
  >
    <template v-slot:activator="{ on }">
      <slot name="activator" :on="on"></slot>
    </template>

    <template v-slot:modal-body>
      <v-form ref="editRewardForm" class="pt-1">
        <base-text-field
          v-model="rewardData.giftCardTitle"
          label="Amazon giftcard title"
          class="loyalty-rewards-info__field mb-10"
          :rules="[$options.validationRules.REQUIRED]"
        />
        <base-text-field
          v-model="giftCardPoints"
          label="Amazon giftcard redeem threshold"
          class="loyalty-rewards-info__field mb-10"
          :rules="[$options.validationRules.REQUIRED]"
        />
        <base-text-field
          v-model="freeProductsPoints"
          label="Free products redeem threshold"
          class="loyalty-rewards-info__field"
          :rules="[$options.validationRules.REQUIRED]"
        />
      </v-form>
    </template>
  </update-info-modal>
</template>

<script>
import BaseTextField from '@/components/common/BaseTextField.vue';
import UpdateInfoModal from '@/components/common/UpdateInfoModal.vue';

import { updateLoyaltyRewardsSettings } from '@/api/countries.api';
import { formatNumberWithSpaces, deleteSpaces } from '@/utils';

import { VALIDATION_RULES, ERROR_BUTTON_COLOR, DEFAULT_BUTTON_COLOR } from '@/constants/common';

export default {
  name: 'UpdateRewardModal',
  components: { UpdateInfoModal, BaseTextField },
  validationRules: VALIDATION_RULES,
  props: {
    countryId: {
      type: Number,
      default: null
    },
    loyaltySettings: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      rewardData: {},
      isModalShown: false,
      isLoading: false,
      actionButtonColor: DEFAULT_BUTTON_COLOR
    };
  },
  computed: {
    giftCardPoints: {
      get() {
        return formatNumberWithSpaces(this.rewardData.giftCardPoints);
      },
      set(value) {
        this.rewardData.giftCardPoints = deleteSpaces(value);
      }
    },
    freeProductsPoints: {
      get() {
        return formatNumberWithSpaces(this.rewardData.freeProductsPoints);
      },
      set(value) {
        this.rewardData.freeProductsPoints = deleteSpaces(value);
      }
    }
  },
  watch: {
    loyaltySettings: {
      handler() {
        this.rewardData = { ...this.loyaltySettings };
      },
      immediate: true
    }
  },
  methods: {
    onModalClose() {
      const { editRewardForm } = this.$refs;

      editRewardForm.resetValidation();

      this.isModalShown = false;
    },
    async onActionButtonClick() {
      const { editRewardForm } = this.$refs;

      if (!editRewardForm.validate()) {
        return;
      }

      this.isLoading = true;

      try {
        await updateLoyaltyRewardsSettings({
          countryId: this.countryId,
          loyaltyRewardsSettings: {
            ...this.rewardData
          }
        });

        this.isModalShown = false;
        this.$emit('update-loyalty-rewards-settings');
      } catch (error) {
        this.actionButtonColor = ERROR_BUTTON_COLOR;
      } finally {
        this.isLoading = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.loyalty-rewards-info__field {
  font-size: 14px;
  line-height: 16px;
}
</style>
