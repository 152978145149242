<template>
  <div class="details-page-header__container d-flex align-center justify-space-between flex-grow-1">
    <div class="details-page-header d-flex align-center">
      <v-hover v-slot:default="{ hover }">
        <div class="details-page-header__avatar-wrapper">
          <v-avatar
            :class="{ 'details-page-header__avatar--hover': hover }"
            color="light-blue lighten-5"
            size="160"
          >
            <v-img
              v-if="headerInfo.photo"
              :src="headerInfo.photo"
              alt="Details photo"
              width="160"
              height="160"
            />
            <v-icon v-else size="55">mdi-account-outline</v-icon>
          </v-avatar>

          <div
            v-if="hover"
            class="details-page-header__photo-controls d-flex flex-column align-center justify-center"
          >
            <v-btn
              class="light-blue--text text--darken-4 mb-3"
              :loading="photoLoading"
              text
              @click="changePhoto"
            >
              <span class="details-photo-controls__button-text">
                Upload
              </span>
            </v-btn>

            <v-btn
              v-if="headerInfo.photo"
              class="light-blue--text text--darken-4"
              text
              @click="deletePhoto"
            >
              <span class="details-photo-controls__button-text">
                Remove
              </span>
            </v-btn>
          </div>
        </div>
      </v-hover>

      <div class="details-page-header__name header-details-name ml-5 d-flex flex-column">
        <span class="display-1 align-self-center">{{ headerInfo.name }}</span>
        <span class="header-details-name__id body-2 blue-grey--text text--lighten-2 mt-2">
          Id {{ headerInfo.id }}
        </span>
      </div>
    </div>

    <input ref="user-photo" class="d-none" type="file" accept="image/*" @change="photoLoaded" />

    <base-modal
      v-model="isPhotoErrorModalOpened"
      action-button-label="Upload another"
      width="400px"
      @action-button-click="changePhoto"
      @cancel="closePhotoValidationModal"
    >
      <template v-slot:modal-body>{{ validationError }}</template>
    </base-modal>

    <slot name="actions"></slot>
  </div>
</template>

<script>
import BaseModal from '@/components/common/BaseModal.vue';

import { getFileType, compressImageFile } from '@/utils';

import { updateDoctorPhoto, deleteDoctorPhoto } from '@/api/users.api';

import {
  ALLOWED_FILE_TYPE,
  MAX_PHOTO_SIZE_IN_BYTES,
  PHOTO_VALIDATION_ERRORS
} from '@/constants/users-page';

const PHOTO_VALIDATION_ERRORS_LABELS = {
  [PHOTO_VALIDATION_ERRORS.TYPE_NOT_ALLOWED]:
    'The selected file is not an image. Please try another.',
  [PHOTO_VALIDATION_ERRORS.MAX_FILE_SIZE]:
    'Photo size exceeds maximum allowable size (15Mb). Please try another.'
};

export default {
  name: 'DetailsPageHeader',
  components: { BaseModal },
  props: {
    headerInfo: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      photoLoading: false,
      isPhotoErrorModalOpened: false,
      validationError: null
    };
  },
  methods: {
    changePhoto() {
      this.$refs['user-photo'].click();
      this.closePhotoValidationModal();
    },
    async deletePhoto() {
      await deleteDoctorPhoto({ userId: this.headerInfo.id });
      this.$emit('update-user');
    },
    resetFileInput() {
      this.$refs['user-photo'].value = '';
    },
    closePhotoValidationModal() {
      this.isPhotoErrorModalOpened = false;
    },
    async photoLoaded({ target: { files } }) {
      if (files.length === 0) {
        return;
      }

      const [file] = files;
      const { size: fileSize } = file;
      const [fileType] = getFileType(file);

      if (fileType !== ALLOWED_FILE_TYPE) {
        this.isPhotoErrorModalOpened = true;
        this.validationError =
          PHOTO_VALIDATION_ERRORS_LABELS[PHOTO_VALIDATION_ERRORS.TYPE_NOT_ALLOWED];

        this.resetFileInput();

        return;
      }

      if (fileSize > MAX_PHOTO_SIZE_IN_BYTES) {
        this.isPhotoErrorModalOpened = true;
        this.validationError =
          PHOTO_VALIDATION_ERRORS_LABELS[PHOTO_VALIDATION_ERRORS.MAX_FILE_SIZE];

        this.resetFileInput();

        return;
      }

      this.photoLoading = true;
      const compressedImage = await compressImageFile(file);

      await updateDoctorPhoto({ userId: this.headerInfo.id, photo: compressedImage });
      this.$emit('update-user');
      this.photoLoading = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.details-page-header {
  &__avatar--hover {
    opacity: 0.2;
  }

  &__photo-controls {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  &__avatar-wrapper {
    position: relative;
  }
}

.details-photo-controls__button-text {
  border-bottom: 1px solid #01579b;
}
</style>
