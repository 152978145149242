<template>
  <v-dialog persistent v-bind="$attrs" v-on="$listeners">
    <template v-slot:activator="{ on }">
      <slot name="activator" :on="on"></slot>
    </template>

    <v-card class="px-7">
      <slot name="modal-title">
        <v-card-title v-if="modalTitle || hasCloseButton" class="px-0 pt-9 pb-5">
          <h1 class="title font-weight-regular">{{ modalTitle }}</h1>
          <v-btn
            v-if="hasCloseButton"
            class="ml-auto mr-n1"
            small
            text
            icon
            color="black"
            @click="onCancelClick"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
      </slot>

      <v-card-text
        class="base-modal__content pa-0 d-flex justify-center body-1"
        :class="modalBodyClasses"
      >
        <slot name="modal-body"></slot>
      </v-card-text>

      <v-card-actions class="py-7 d-flex justify-center">
        <slot name="modal-actions">
          <v-btn
            class="white--text"
            color="light-blue darken-4"
            :loading="actionButtonLoading"
            tile
            large
            @click="onActionButtonClick"
          >
            {{ actionButtonLabel }}
          </v-btn>
          <v-btn class="ml-5" color="light-blue darken-4" text @click="onCancelClick">
            Cancel
          </v-btn>
        </slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'BaseModal',
  props: {
    actionButtonLabel: {
      type: String,
      default: ''
    },
    actionButtonLoading: {
      type: Boolean,
      default: false
    },
    modalTitle: {
      type: String,
      default: ''
    },
    hasCloseButton: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    modalBodyClasses() {
      const modalBodySlot = this.$slots['modal-body'];

      return { 'pt-5': modalBodySlot, 'pt-0': !modalBodySlot };
    }
  },
  methods: {
    onActionButtonClick() {
      this.$emit('action-button-click');
    },
    onCancelClick() {
      this.$emit('cancel');
    }
  }
};
</script>
